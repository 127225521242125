import * as React from 'react';
import * as BABYLON from 'babylonjs';
export default class Scene extends React.Component {
  constructor() {
    super(...arguments);
    this.onResizeWindow = () => {
      if (this.engine) {
        this.engine.resize();
      }
    };
    this.onCanvasLoaded = c => {
      if (c !== null) {
        this.canvas = c;
      }
    };
    window.addEventListener('resize', this.resize);
  }
  
  resize = () => {
    this.canvas.width = this.canvas.parentNode.clientWidth;
    // this.canvas.height = this.canvas.parentNode.clientHeight;
  }

  componentDidMount() {
    this.resize();
    this.engine = new BABYLON.Engine(
      this.canvas,
      true,
      this.props.engineOptions,
      this.props.adaptToDeviceRatio
    );
    let scene = new BABYLON.Scene(this.engine);
    this.scene = scene;
    if (typeof this.props.onSceneMount === 'function') {
      this.props.onSceneMount({
        scene,
        engine: this.engine,
        canvas: this.canvas
      });
    } else {
      console.error('onSceneMount function not available');
    }
    // Resize the babylon engine when the window is resized
    window.addEventListener('resize', this.onResizeWindow);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.onResizeWindow);
  }
  render() {
    // 'rest' can contain additional properties that you can flow through to canvas:
    // (id, className, etc.)
    return React.createElement(
      'canvas',
      Object.assign(
        { height: 300 },
        { ref: this.onCanvasLoaded }
      )
    );
  }
}
