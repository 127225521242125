import React, { Component } from 'react';
import * as BABYLON from 'babylonjs';
import 'babylonjs-loaders';
import BabylonScene from './babylon';

class ObjectDisplay extends Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();
    this.state = {
      width: ''
    };
  }

  componentDidMount() {
    this.setState({
      width: this.input.current.offsetWidth
    });
  }

  onSceneMount = e => {
    const { canvas, scene: localscene, engine } = e;

    // This creates and positions a free camera (non-mesh)
    var camera = new BABYLON.ArcRotateCamera(
      'Camera',
      1,
      1,
      5,
      BABYLON.Vector3.Zero(),
      localscene
    );

    // This attaches the camera to the canvas
    camera.attachControl(canvas, false);

    // var light = new BABYLON.DirectionalLight(
    //   'Light',
    //   new BABYLON.Vector3(0, -1, 0),
    //   localscene
    // );

    BABYLON.SceneLoader.ImportMesh(
      '',
      'https://s3-us-west-2.amazonaws.com/gudongfeng.me/3dModel/',
      'teapot.glb',
      localscene,
      function(meshes) {
        // Resize the object
        meshes[0].scaling = new BABYLON.Vector3(7, 7, 7);
        meshes[0].position = new BABYLON.Vector3(0, 3, 0);
      }      
    );

    engine.runRenderLoop(() => {
      if (localscene) {
        localscene.render();
      }
    });
  };

  render() {
    return (
      <div className="card" ref={this.input}>
        <BabylonScene onSceneMount={this.onSceneMount} />
        <div className="card-body">
          <h5 className="card-title"> 3D Object Interaction</h5>
          <p> interact with the 3D object using your mouse </p>
        </div>
      </div>
    );
  }
}

export default ObjectDisplay;
