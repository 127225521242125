import React, { Component } from 'react'
import axios from "axios";
import { Auth } from '@aws-amplify/auth'

class Summarization extends Component {
    constructor(props) {
        super(props);
        this.state = {
            artile: null,
            summarization: null
        };
    }

    handleSummarizationSubmit = event => {
        event.preventDefault();
        var that = this;
        var summarizationApiGatewayEndpoint = 'https://lcmaxphm18.execute-api.us-east-1.amazonaws.com/prod'
        const model = {
            input: this.state.artile
        }

        Auth.currentAuthenticatedUser().then(user => {
            axios
                .post(`${summarizationApiGatewayEndpoint}/summarization`, model, {
                    headers: {
                        Authorization: user.signInUserSession.idToken.jwtToken
                    },
                })
                .then((response) => {
                    if (response.data.error) {
                        alert(response.data.message);
                    } else {
                        this.setState({ summarization: response.data.summary_text })
                    }
                })
                .catch((err) => {
                    // eslint-disable-next-line
                    console.log(`POST to ${that.summarizationApiGatewayEndpoint}/summarization caught error ${err}`);
                    alert("SlipLink cannot be created. Bad format.");
                });
        })
    }

    handlArticleChange = event => {
        this.setState({ artile: event.target.value })
    }

    render() {
        return (
            <div className="card card-body">
                <h5 className="card-title">Summarization</h5>
                <form onSubmit={this.handleSummarizationSubmit}>
                    <div className="form-group">
                        <label>Article</label>
                        <textarea
                            type="text"
                            onChange={this.handlArticleChange}
                            className="form-control"
                            rows="3"
                        />
                    </div>
                    <input type="submit" className="btn btn-primary" value="Summarize" />
                    <div className="form-group">
                        <label>Summarization Result</label>
                        <textarea
                            type="text"
                            value={this.state.summarization}
                            className="form-control"
                            rows="3"
                        />
                    </div>
                </form>
            </div>
        )
    }
}

export default Summarization