import React, { Component } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { css } from 'glamor';

import Form from './Form';
import Notes from './Notes';
import { createNote, updateNote, deleteNote } from '../../graphql/mutations';
import { listNotes } from '../../graphql/queries';

class Notebook extends Component {
  state = { notes: [], filter: 'none' };
  async componentDidMount() {
    try {
      const {
        data: {
          listNotes: { items }
        }
      } = await API.graphql(graphqlOperation(listNotes));
      this.setState({ notes: items });
    } catch (err) {
      console.error('error fetching notes...', err);
    }
  }
  createNote = async note => {
    const notes = [note, ...this.state.notes];
    const newNotes = this.state.notes;
    this.setState({ notes });
    try {
      const data = await API.graphql(
        graphqlOperation(createNote, { input: note })
      );
      this.setState({ notes: [data.data.createNote, ...newNotes] });
    } catch (err) {
      console.error('error creating note..', err);
    }
  };
  updateNote = async note => {
    const updatedNote = {
      ...note,
      status: note.status === 'new' ? 'completed' : 'new'
    };
    const index = this.state.notes.findIndex(i => i.id === note.id);
    const notes = [...this.state.notes];
    notes[index] = updatedNote;
    this.setState({ notes });

    // Remove the owner attributes before we update the record.
    var clone = Object.assign({}, updatedNote);
    delete clone["owner"]
    try {
      await API.graphql(graphqlOperation(updateNote, { input: clone }));
    } catch (err) {
      console.error('error updating note...', err);
    }
  };
  deleteNote = async note => {
    const input = { id: note.id };
    const notes = this.state.notes.filter(n => n.id !== note.id);
    this.setState({ notes });
    try {
      await API.graphql(graphqlOperation(deleteNote, { input }));
    } catch (err) {
      console.error('error deleting note...', err);
    }
  };
  updateFilter = filter => this.setState({ filter });
  render() {
    let { notes, filter } = this.state;
    if (filter === 'completed') {
      notes = notes.filter(n => n.status === 'completed');
    }
    if (filter === 'new') {
      notes = notes.filter(n => n.status === 'new');
    }
    return (
      <div className="card card-body">
        <h5 className="cars-title">Todos</h5>
        <Form createNote={this.createNote} />
        <Notes
          notes={notes}
          deleteNote={this.deleteNote}
          updateNote={this.updateNote}
        />
        <div {...css(styles.bottomMenu)}>
          <p
            onClick={() => this.updateFilter('none')}
            {...css([styles.menuItem, getStyle('none', filter)])}
          >
            All
          </p>
          <p
            onClick={() => this.updateFilter('completed')}
            {...css([styles.menuItem, getStyle('completed', filter)])}
          >
            Completed
          </p>
          <p
            onClick={() => this.updateFilter('new')}
            {...css([styles.menuItem, getStyle('new', filter)])}
          >
            Pending
          </p>
        </div>
      </div>
    );
  }
}

function getStyle(type, filter) {
  if (type === filter) {
    return {
      fontWeight: 'bold'
    };
  }
}

const styles = {
  bottomMenu: {
    display: 'flex',
    marginTop: '1rem',
    justifyContent: 'center'
  },
  menuItem: {
    cursor: 'pointer',
    marginRight: '1rem'
  },
  container: {
    textAlign: 'center'
  }
};

export default Notebook;
