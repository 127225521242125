import React, { Component } from 'react';

class BabyName extends Component {
  render() {
    return (
      <div className="card card-body">
        <h5 className="card-title">2016 popular baby name</h5>
        <iframe
          title="BabyName"
          src="https://public.tableau.com/views/BabyName_15781916502260/Dashboard1?:embed=yes"
          height="600"
          frameBorder="0"
        >
        </iframe>
      </div>
    );
  }
}

export default BabyName;
