import React, { Component } from 'react';
import Auth from '@aws-amplify/auth';
import S3 from 'aws-sdk/clients/s3';

class ObjectDetection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: '',
      imagePreviewUrl: 'http://placehold.it/300',
      s3ObjectKey: '',
      detecedImageUrl: ''
    };
  }

  createRandomId = length => {
    var result = '';
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  s3ImageUpdate = event => {
    event.preventDefault();

    // Make sure the image has been chosen
    if (!this.state.file) {
      return alert('Please choose a file to upload');
    }

    Auth.currentCredentials().then(credentials => {
      const that = this;
      // Authenticate the request
      const s3Client = new S3({
        apiVersion: '2006-03-01',
        params: { Bucket: 'gudongfeng.me' },
        region: 'us-west-2',
        credentials: Auth.essentialCredentials(credentials)
      });

      // Upload the image to s3
      this.setState({
        s3ObjectKey:
          'images/' + this.createRandomId(12) + '_' + this.state.file.name
      });

      s3Client.upload(
        { Key: this.state.s3ObjectKey, Body: this.state.file },
        function(err, data) {
          if (err) {
            console.error(err.message);
            return alert('There was an error uploading your photo: ', err.message);
          }
          // Detect the image
          that.objectDetection();
        }
      );

    });
  };

  objectDetection = () => {
    const objectDetectionEndpoint =
      'https://812hxld73f.execute-api.us-east-1.amazonaws.com/dev/detect';
    fetch(objectDetectionEndpoint, {
      method: 'Post',
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
      body: JSON.stringify({
        url: this.getS3Url('gudongfeng.me', this.state.s3ObjectKey)
      })
    })
      .then(res => res.json())
      .then(res => {
        this.setState({
          imagePreviewUrl: res.s3_url
        });
      })
      .catch(err => {
        alert(err);
      });
  };

  getS3Url = (s3_bucket_name, key_name) => {
    return `https://s3-us-west-2.amazonaws.com/${s3_bucket_name}/${key_name}`;
  };

  preViewImage = event => {
    event.preventDefault();

    const reader = new FileReader();
    const file = event.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result
      });
    };

    reader.readAsDataURL(file);
  };

  render() {
    return (
      <div className="card card-body">
        <img
          alt="detection"
          className="card-img-top"
          src={this.state.imagePreviewUrl}
        />
        <div className="card-body">
          <h5 className="card-title">Object Dectection</h5>
          <p className="card-text">
            Usage: <br></br> Login with your google account through Google
            Oauth, then choose file and press Detect button (Please wait for
            several seconds until you see a response)
          </p>
          <form onSubmit={this.s3ImageUpdate}>
            <div className="form-group">
              <input
                type="file"
                accept="image/*"
                onChange={this.preViewImage}
                className="form-control-file"
              />
            </div>
            <input type="submit" value="Detect" className="btn btn-primary" />
          </form>
        </div>
      </div>
    );
  }
}

export default ObjectDetection;
