import React, { Component } from 'react'
import axios from "axios";
import { Auth } from '@aws-amplify/auth'

class TinyUrl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            originUrl: null,
            shortenUrl: null
        };
    }

    handleTinyUrlSubmit = event => {
        event.preventDefault();
        // TODO: Generate the URL
        var that = this;
        var shortenUrlApiGatewayEndpoint = 'https://tiny.gudongfeng.me'
        const shortenUrl = Math.random().toString(36).substring(2, 7);
        const model = {
            id: shortenUrl,
            url: this.state.originUrl
        }

        Auth.currentAuthenticatedUser().then(user => {
            axios
                .post(`${shortenUrlApiGatewayEndpoint}/app`, model, {
                    headers: {
                        Authorization: user.signInUserSession.idToken.jwtToken
                    },
                })
                .then((response) => {
                    if (response.data.error) {
                        alert(response.data.message);
                    } else {
                        this.setState({ shortenUrl: "https://tiny.gudongfeng.me/" + shortenUrl })
                    }
                })
                .catch((err) => {
                    // eslint-disable-next-line
                    console.log(`POST to ${that.apiUrl}/app caught error ${err}`);
                    alert("SlipLink cannot be created. Bad format.");
                });
        })
    }

    handlOriginUrlChange = event => {
        this.setState({ originUrl: event.target.value })
    }

    render() {
        return (
            <div className="card card-body">
                <h5 className="card-title">TinyUrl Generator</h5>
                <form onSubmit={this.handleTinyUrlSubmit}>
                    <div className="form-group">
                        <label>Origin Url</label>
                        <input
                            type="text"
                            onChange={this.handlOriginUrlChange}
                            className="form-control"
                        />
                    </div>
                    <input type="submit" className="btn btn-primary" value="Shorten" />
                    <div className="form-group">
                        <label>Shorten Url</label>
                        <input
                            type="text"
                            value={this.state.shortenUrl}
                            className="form-control"
                        />
                    </div>
                </form>
            </div>
        )
    }
}

export default TinyUrl