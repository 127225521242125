import React, { Component } from 'react';
import axios from "axios";
import { Auth } from '@aws-amplify/auth'

class MultiRegion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputData: null,
      dataUUID: null,
      value: null
    };
  }

  handleButtonClick = () => {
    var that = this;
    var domain =
      `https://api.gudongfeng.me/data/${this.state.dataUUID}`;
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        that.setState({ value: this.responseText });
      }
    };
    xhttp.open('GET', domain, true);
    xhttp.send();
  };

  handlePostDataSubmit = event => {
    event.preventDefault();

    var endpoint = 'https://api.gudongfeng.me/data'
    const model = {
      name: this.state.inputData
    }

    Auth.currentAuthenticatedUser().then(user => {
      axios
        .post(endpoint, model, {
          headers: {
            Authorization: user.signInUserSession.idToken.jwtToken
          },
        })
        .then((response) => {
          if (response.data.error) {
            alert(response.data.message);
          } else {
            this.setState({ dataUUID: response.data.requestId })
          }
        })
        .catch((err) => {
          // eslint-disable-next-line
          console.log(`POST to ${endpoint} caught error ${err}`);
          alert("SlipLink cannot be created. Bad format.");
        });
    })
  }

  handlPostDataChange = event => {
    this.setState({ inputData: event.target.value })
  }

  handlDataUUIDChange = event => {
    this.setState({ dataUUID: event.target.value })
  }

  render() {
    return (
      <div className="card card-body">
        <h5 className="card-title">Multi Region Check</h5>
        <form onSubmit={this.handlePostDataSubmit}>
          <div className="form-group">
            <label>Data Input:</label>
            <input
              type="text"
              onChange={this.handlPostDataChange}
              className="form-control"
            />
          </div>
          <input type="submit" className="btn btn-primary" value="Post Data" />
          <div className="form-group">
            <label>Data Unique ID Output:</label>
            <input
              type="text"
              onChange={this.handlDataUUIDChange}
              value={this.state.dataUUID}
              className="form-control"
            />
          </div>
        </form>
        <p className="card-text">
          This will invoke a lambda function and print out the following:
        </p>
        <p>{this.state.value}</p>
        <button onClick={this.handleButtonClick} className="btn btn-primary">
          Get Data
        </button>
      </div>
    );
  }
}

export default MultiRegion;
