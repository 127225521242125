import React from 'react';
import HelloWorld from '../Helloworld';
import TextToSpeech from '../TextToSpeech';
import ObjectDetection from '../ObjectDetection';
import ObjectDisplay from '../ObjectDisplay';
import Notebook from '../Notebook';
import BabyName from '../BabyName';
import TinyUrl from '../TinyUrl';
import Summarization from '../Summarization'
import { withAuthenticator } from '@aws-amplify/ui-react'
import aws_exports from '../../aws-exports';
import Amplify from 'aws-amplify'
import MultiRegion from '../MultiRegion';

Amplify.configure(aws_exports);

const App = () => (
  <div>
    <br></br>
    <div className="card-columns">
      <HelloWorld />
      <Notebook />
      <BabyName />
      <TextToSpeech />
      <TinyUrl />
      <Summarization />
      <ObjectDetection />
      <ObjectDisplay />
      <MultiRegion />
    </div>
    <footer className="page-footer font-small blue pt-4">
      <div className="footer-copyright text-center py-3">
        © 2018 Copyright:
        <a href="https://blog.gdf.name">
          {' '}
          gudongfeng
        </a>
      </div>
    </footer>
  </div>
);

export default withAuthenticator(App, true);
