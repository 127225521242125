import React, { Component } from 'react';

class TextToSpeech extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectLanguage: 'Ivy',
      textBody: 'Please write the content that you want to speech',
      textLength: 48,
      speechId: '(Use this ID to retrieve the speech)',
      inputSpeechId: 'None',
      playerComponent: '',
      pollyApiEndpoint:
        'https://cqkwx7o9ch.execute-api.us-west-2.amazonaws.com/prod'
    };
  }

  handleGenerateSubmit = event => {
    event.preventDefault();
    var requestBody = {
      voice: this.state.selectLanguage,
      text: this.state.textBody
    };
    fetch(this.state.pollyApiEndpoint, {
      method: 'Post',
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify(requestBody)
    })
      .then(res => res.json())
      .then(response => {
        this.setState({ speechId: response, inputSpeechId: response });
      })
      .catch(error => {
        alert(error);
      });
  };

  handleSearchSubmit = event => {
    event.preventDefault();
    var requestUrl =
      this.state.pollyApiEndpoint + '?postId=' + this.state.inputSpeechId;
    fetch(requestUrl)
      .then(res => res.json())
      .then(response => {
        var url = response[0]['url'];
        if (url !== 'undefined') {
          this.setState({
            playerComponent: url
          }, function() {
            this.refs.audio.pause();
            this.refs.audio.load();
          });
        }
      })
      .catch(error => {
        alert(error);
      });
  };

  handleSelectLanguageChange = event => {
    this.setState({ selectLanguage: event.target.value });
  };

  handleTextAeraChange = event => {
    this.setState({
      textBody: event.target.value,
      textLength: event.target.value.length
    });
  };

  handleSpeechIdInputChange = event => {
    this.setState({ inputSpeechId: event.target.value });
  };

  render() {
    return (
      <div className="card card-body">
        <form onSubmit={this.handleGenerateSubmit}>
          <h5 className="card-title">Text to Speech converter</h5>
          <p className="card-text">This will convert the text to speech</p>
          <div className="form-group">
            <label>Voice Type</label>
            <select
              value={this.state.selectLanguage}
              onChange={this.handleSelectLanguageChange}
              className="form-control"
            >
              <option value="Ivy">Ivy [English - American]</option>
              <option value="Zhiyu">Zhiyu [Chinese - Mandarin]</option>
              <option value="Joanna">Joanna [English - American]</option>
              <option value="Joey">Joey [English - American]</option>
              <option value="Justin">Justin [English - American]</option>
              <option value="Kendra">Kendra [English - American]</option>
              <option value="Kimberly">Kimberly [English - American]</option>
              <option value="Salli">Salli [English - American]</option>
              <option value="Nicole">Nicole [English - Australian]</option>
              <option value="Russell">Russell [English - Australian]</option>
              <option value="Emma">Emma [English - British]</option>
              <option value="Brian">Brian [English - British]</option>
              <option value="Amy">Amy [English - British]</option>
              <option value="Raveena">Raveena [English - Indian]</option>
              <option value="Geraint">Geraint [English - Welsh]</option>
              <option value="Ricardo">Ricardo [Brazilian Portuguese]</option>
              <option value="Vitoria">Vitoria [Brazilian Portuguese]</option>
              <option value="Lotte">Lotte [Dutch]</option>
              <option value="Ruben">Ruben [Dutch]</option>
              <option value="Mathieu">Mathieu [French]</option>
              <option value="Celine">Celine [French]</option>
              <option value="Chantal">Chantal [Canadian French]</option>
              <option value="Marlene">Marlene [German]</option>
              <option value="Dora">Dora [Icelandic]</option>
              <option value="Karl">Karl [Icelandic]</option>
              <option value="Carla">Carla [Italian]</option>
              <option value="Giorgio">Giorgio [Italian]</option>
              <option value="Mizuki">Mizuki [Japanese]</option>
              <option value="Liv">Liv [Norwegian]</option>
              <option value="Maja">Maja [Polish]</option>
              <option value="Jan">Jan [Polish]</option>
              <option value="Ewa">Ewa [Polish]</option>
              <option value="Cristiano">Cristiano [Portuquese]</option>
              <option value="Ines">Ines [Portuquese]</option>
              <option value="Carmen">Carmen [Romanian]</option>
              <option value="Maxim">Maxim [Russian]</option>
              <option value="Tatyana">Tatyana [Russian]</option>
              <option value="Enrique">Enrique [Spanish]</option>
              <option value="Penelope">Penelope [US Spanish]</option>
              <option value="Enrique">Miguel [US Spanish]</option>
              <option value="Conchita">Conchita [Castilian Spanish]</option>
              <option value="Astrid">Astrid [Swedish]</option>
              <option value="Filiz">Filiz [Turkish]</option>
              <option value="Gwyneth">Gwyneth [Welsh]</option>
            </select>
          </div>
          <div className="form-group">
            <label>Text Area</label>
            <textarea
              value={this.state.textBody}
              onChange={this.handleTextAeraChange}
              className="form-control"
              rows="3"
            ></textarea>
            <span>Characters: {this.state.textLength}</span>
          </div>
          <div className="form-group">
            <label>Speech ID</label>
            <span className="form-control">{this.state.speechId}</span>
          </div>
          <input type="submit" value="Say it!" className="btn btn-primary" />
        </form>
        <form onSubmit={this.handleSearchSubmit}>
          <div className="form-group">
            <label>Input Speech ID</label>
            <input
              type="text"
              value={this.state.inputSpeechId}
              onChange={this.handleSpeechIdInputChange}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Audio</label>
            <audio className="form-control" controls ref="audio">
              <source src={this.state.playerComponent} />
            </audio>
          </div>
          <input type="submit" className="btn btn-primary" value="Search" />
        </form>
      </div>
    );
  }
}

export default TextToSpeech;
