import React from 'react'
import { css } from 'glamor'
import { MdAdd } from 'react-icons/md'

class Form extends React.Component {
  state = { name: '' };
  onChange = e => {
    this.setState({ name: e.target.value });
  };
  handleKeyPress = e => {
    if (e.key === 'Enter' && this.state.name !== '') {
      const note = {
        ...this.state,
        status: 'new'
      };
      this.props.createNote(note);
      this.setState({ name: '' });
    }
  };
  render() {
    return (
      <div {...css(styles.container)}>
        <div {...css(styles.form)}>
          <MdAdd size={20} />
          <input
            placeholder="Note Name"
            {...css(styles.input)}
            onKeyPress={this.handleKeyPress}
            onChange={e => this.onChange(e)}
            value={this.state.name}
          />
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    borderBottom: '1px solid #ededed',
  },
  form: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center'
  },
  input: {
    border: 'none',
    outline: 'none',
    fontSize: '1rem',
    padding: '1rem',
  }
}

export default Form