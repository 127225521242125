import React, { Component } from 'react';

class HelloWorld extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null
    };
  }

  handleButtonClick = () => {
    var that = this;
    var labmdaEndpoint =
      'https://b5lahvnrpl.execute-api.us-west-2.amazonaws.com/prod/myServerlessWebsite';
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function() {
      if (this.readyState === 4 && this.status === 200) {
        that.setState({ value: this.responseText });
      }
    };
    xhttp.open('GET', labmdaEndpoint, true);
    xhttp.send();
  };

  render() {
    return (
      <div className="card card-body">
        <h5 className="card-title">Hello world! {this.state.value}</h5>
        <p className="card-text">
          This will invoke a lambda function and change the title of this card
        </p>
        <button onClick={this.handleButtonClick} className="btn btn-primary">
          Click Me
        </button>
      </div>
    );
  }
}

export default HelloWorld;
